import { Inject, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import {
  ClpInitConfig,
  ClpLoginResponse,
  HTTP_STATUS,
  LoginAlertModel,
  LoginConfigService,
  getLoginInformation,
  OFFICE_ID_REGEXP,
  LoginInformation,
  MFA_ID
} from '@seco/login';
import { LocalesService } from '../services/locales/locales.service';
import { CoreState, selectClpConfig, selectTitleInformation, selectFormAlert } from '../redux/core.reducer';
import { FormAlert } from '../model/form-alert';
import { AuthService } from '../services/auth/auth.service';
import { TitleInformation, TitleState } from '../model/title-information';
import { AlertModel, AlertType, Flow } from '../model/alert.model';
import { AlertService } from '../services/alert/alert.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { Analytics, HttpService } from '@seco/core';
import { UtilService } from '../services/util/util.service';
import { ClpInitConfigExtended } from '../model/clpInitConfigExtended.model';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnDestroy {
  signInStatus$ = new BehaviorSubject<HTTP_STATUS>(HTTP_STATUS.init);
  titleInformation$ = new Observable<TitleInformation>();
  globalFormError$: Observable<FormAlert | undefined>;
  clpConfig$: Observable<ClpInitConfigExtended | undefined>;
  loginInformation$: Observable<LoginInformation | undefined>;
  signedOut = false;
  impersonate = false;
  isMobile: boolean;
  isMfaAlertDisplayed = true;
  isPhishingAlertDisplayed = true;
  isSelfSelectionAlertDisplayed = true;
  selectedMFAId: MFA_ID | undefined;
  titleState: TitleState | undefined;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly httpService: HttpService,
    private readonly store: Store<CoreState>,
    private readonly authService: AuthService,
    private readonly utilService: UtilService,
    private readonly loginConfigService: LoginConfigService,
    private readonly localesService: LocalesService,
    private readonly alertService: AlertService,
    private readonly localStorageService: LocalStorageService,
    private readonly analytics: Analytics,
    @Inject('Window') private readonly window: Window
  ) {
    this.activateCookieAlert();
    this.urlParams();
    this.loginConfigService.setConfig({
      clpAppId: 'SECO',
      signInStatus: this.signInStatus$,
      language: this.localesService.getLanguageCLPCode(),
      clpConfig: this.store.select(selectClpConfig) as Observable<ClpInitConfig>,
      useSSO: false,
      passwordPolicyModule: 'newlogin',
      referenceNumber: '12455760',
      onSuccess: this.onClpLoginSuccess.bind(this),
      onError: this.onClpLoginError.bind(this)
    });
    this.titleInformation$ = this.store.select(selectTitleInformation);
    this.clpConfig$ = this.store.select(selectClpConfig);
    this.globalFormError$ = this.store.select(selectFormAlert);
    this.loginInformation$ = this.store.select(getLoginInformation);
    this.isMobile = this.utilService.isSecoMobile();
    this.subscriptions.add(
      this.loginInformation$.subscribe((logInfo) => {
        this.selectedMFAId = logInfo?.selectedMFA?.id;
      })
    );
    this.subscriptions.add(
      this.titleInformation$.subscribe((titleInfo) => {
        this.titleState = titleInfo?.titleState;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  urlParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['event'] === 'LOGIN_LOGOUT') {
        const alert: AlertModel = {
          type: AlertType.SUCCESS,
          message: $localize`:@@newlogin.logout.success:You have been successfully logged out`,
          state: Flow.MESSAGE
        };
        this.alertService.addAlert(alert);
      }
      if (params['err'] || params['eventCode']) {
        const data = params['eventCode'] ? this.utilService.decodeString(params['eventCode']) : params['err'];
        const defaultMessage = $localize`:@@newlogin.defaultErrorMessage:Technical error occurred (999999)`;
        this.httpService.postApfPlus('newlogin', 'getErrorMessage', data).subscribe({
          next: (res: any) => {
            const model = res.response.model;
            const response = (typeof model === 'string' && model) || defaultMessage;
            const alert: AlertModel = {
              type: AlertType.ERROR,
              message: response,
              state: Flow.MESSAGE
            };
            this.alertService.addAlert(alert);
          },
          error: () => {
            const alert: AlertModel = {
              type: AlertType.ERROR,
              message: defaultMessage,
              state: Flow.MESSAGE
            };
            this.alertService.addAlert(alert);
          }
        });
      }
      this.impersonate = params.HELPDESK != null;
    });
  }

  public openPhishingAlert(): void {
    this.window.open('https://servicehub.amadeus.com/c/portal/view-solution/1129355533', '_blank');
  }

  public openMfaWarning(): void {
    this.window.open('https://servicehub.amadeus.com/c/portal/view-solution/1208191064', '_blank');
  }

  private onClpLoginSuccess(clpLoginResponse: ClpLoginResponse): void {
    this.signInStatus$.next(HTTP_STATUS.waiting);
    this.localesService.updatePreferedLocaleCookie(this.localesService.getDefaultLocaleUsed(), false);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.authService.redirectBookingFarm(clpLoginResponse, params);
    });
  }

  private onClpLoginError(alertModels: LoginAlertModel[]) {
    if (!alertModels.some((alert) => alert.error?.message?.toUpperCase().includes('CLP'))) {
      alertModels.forEach((alertModel: LoginAlertModel) => {
        const alert = {
          type: AlertType.ERROR,
          data: alertModel.error.message
        };
        this.alertService.addAlert(alert);
        this.store.pipe(select(getLoginInformation), take(1)).subscribe((newLoginInformation) => {
          this.analytics.track({
            action: 'login.clpLoginError',
            action_value: JSON.stringify({
              officeId: newLoginInformation.officeId?.match(OFFICE_ID_REGEXP)
                ? newLoginInformation.officeId
                : undefined,
              type: alertModel.type,
              message: alertModel.error.message,
              code: alertModel.code
            })
          });
        });
      });
    }
  }

  private activateCookieAlert(): void {
    if (this.localStorageService.getStoredItem() === null) {
      const alert: AlertModel = {
        type: AlertType.INFO,
        state: Flow.FIRST_VISIT,
        dismissible: false,
        message: $localize`:@@newlogin.login.cookie.info:Selling Platform Connect uses cookies to give you a better browsing experience, find out more by reading our `
      };
      this.alertService.addAlert(alert);
    }
  }

  isTotpAlreadyRegistered(): boolean {
    return this.selectedMFAId === MFA_ID.TOTP && this.titleState === TitleState.loginTotp;
  }
}
