import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { updateClpConfig, updateTitle, updateFormAlert, clearFormAlert } from './core.actions';
import { ClpInitConfigExtended } from '../model/clpInitConfigExtended.model';
import { TitleInformation, TitleState } from '../model/title-information';
import { FormAlert } from '../model/form-alert';

export const CORE_FEATURE = 'coreLoginReducer';

export interface CoreState {
  clpConfig?: ClpInitConfigExtended;
  titleInformation: TitleInformation;
  formAlert?: FormAlert;
}

export const coreInitialState: CoreState = {
  titleInformation: {
    title: $localize`:@@newlogin.login.signIn:Sign in`,
    subtitle: '',
    titleState: TitleState.login,
    shouldDisplayUsername: false
  }
};

export const coreReducer = createReducer(
  coreInitialState,
  on(updateClpConfig, (state, payload) => ({
    ...state,
    clpConfig: payload
  })),
  on(updateTitle, (state, payload) => ({
    ...state,
    titleInformation: payload
  })),
  on(updateFormAlert, (state, payload) => ({
    ...state,
    formAlert: payload
  })),
  on(clearFormAlert, (state) => ({
    ...state,
    formAlert: undefined
  }))
);

export const selectCore = createFeatureSelector<CoreState>(CORE_FEATURE);
export const selectClpConfig = createSelector(selectCore, (state: CoreState) => state.clpConfig);
export const selectTitleInformation = createSelector(selectCore, (state: CoreState) => state.titleInformation);
export const selectFormAlert = createSelector(selectCore, (state: CoreState) => state.formAlert);
